<template>
	<t-section class="hobbies" id="hobbies" title="Hobbies">
		<b-col v-for="hobby in hobbies" md="4" cols="12" :key="hobby.name">
			<div class="hobbies-wrap" @mouseover="selectHobby(hobby)" @mouseleave="deselectHobby(hobby)">
				<!--				<i class="fa" :class="service.icon" aria-hidden="true"></i>-->
				<font-awesome-icon class="hobby-icon" :icon="['fas', hobby.icon]" size="lg" />
				<h4>{{ hobby.name }}</h4>
				<p class="main-text">{{hobby.text}}</p>
			</div>
		</b-col>
	</t-section>
</template>

<script>
	import Section from "@/components/Section";

	export default {
		name: "HobbiesSection",
		components: {
			't-section': Section
		},
		prop: ['hobby'],
		model: {
			prop: 'hobby',
			event: 'select'
		},
		data: function() {
			return {
				hobbies: [
					{
						icon: 'music',
						name: 'Music',
						img: '_img/photo_2021-10-29_22-21-57s.webp',
						text: 'Started learning piano at 9\xa0yo. Currently a multi-instrumentalist with 7 years of live performance experience as well as live and studio audio engineering.',
						title: {
							main: 'musician',
							sub: 'keyboardist'
						}
					},{
						icon: 'gamepad',
						name: 'Gaming',
						text: 'Starting with Super Mario Bros. for the GameBoy Color, video games have been a part of my life for as long as I\xa0remember. Favourite genres include puzzle games, platformers and competitive\xa0FPS.',
						title: {
							main: 'gamer',
							sub: 'completionist'
						}
					},{
						icon: 'futbol',
						name: 'Sports',
						text: 'Parkour practitioner for many years (traceur), currently I\xa0have bouldering and tennis as my go-to sports of choice. Moving helps me keep a clear mind and release pent-up energy.',
						img: '_img/photo_2021-11-10_09-52-09s.webp',
						title: {
							main: 'sportsman',
							sub: 'boulderer'
						}
					},{
						icon: 'microchip',
						name: 'DIY / Electronics',
						text: 'I like learning how to do new things, especially when they have practical applications. Because of this, DIY and electronic projects are a lot of what I do in\xa0my free\xa0time.',
						img: '_img/_MAD0649s.webp',
						title: {
							main:	'maker',
							sub: 'learner'
						}
					},{
						icon: 'camera',
						name: 'Photography',
						text: 'The most recent of my hobbies. I\xa0like how it combines the technical with the artistic to allow you to capture moments in\xa0time.',
						img: '_img/F1010004s.webp',
						title: {
							main: 'amateur photographer'
						}
					},
				],
			}
		},
		methods: {
			selectHobby(new_hobby) {
				this.hobby = new_hobby;
				this.$emit('select', new_hobby);
			},
			deselectHobby(currentHobby) {
				if(this.hobby === currentHobby) {
					this.hobby = null;
					this.$emit('select', null);
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	.hobbies {
		padding-top: 170px;
		padding-bottom: 70px;

		.hobbies-wrap {
			border: 1px solid #fff;
			padding: 25px 0px;
			-webkit-transition: all 0.4s;
			-moz-transition: all 0.4s;
			transition: all 0.4s;

			&:hover {
				border-bottom: 1px solid #1b1b1b;
			}
		}

		.hobby-icon {
			margin-top: 30px;
			font-size: 20pt;
			color: #1b1b1b;
		}

		h4 {
			margin: 25px 0;
			font-size: 12pt;
			font-weight: 400;
			color: #1b1b1b;
			letter-spacing: 1pt;
		}

		p {
			margin-top: 10px;
			font-size: 10pt;
			font-weight: 300;
			color: #757575;
			letter-spacing: 1px;
			line-height: 16pt;
		}

	}
</style>