<template>
	<b-container fluid>
		<!-- Preloader -->
		<Preloader :time=500 />

		<b-row>
			<!-- Image Wrapper Section -->
			<side-image :details="hobby" tag="aside"/>

			<b-col lg="8" class="right-wrapper" tag="main">
<!--					<div class="content-wrapper">-->
					<button class="menu-btn"><i class="fa fa-navicon"></i></button>
					<nav class="pushy pushy-right" data-focus="#first-link">
						<div class="pushy-content">
							<ul>
								<li class="pushy-link"><a href="#about">ABOUT</a></li>
								<li class="pushy-link"><a href="#resume">RESUME</a></li>
								<li class="pushy-link"><a href="#hobbies">HOBBIES</a></li>
								<li class="pushy-link"><a href="#work">WORK</a></li>
								<li class="pushy-link"><a href="#work">PROJECTS</a></li>
								<li class="pushy-link"><a href="#contact">CONTACT</a></li>
								<!-- Submenu -->
<!--									<li class="pushy-submenu">-->
<!--										<button>FOLLOW</button>-->
<!--										<ul>-->
<!--											<li class="pushy-link"><a href="#">FACEBOOK</a></li>-->
<!--											<li class="pushy-link"><a href="#">BEHANCE</a></li>-->
<!--											<li class="pushy-link"><a href="#">TWITTER</a></li>-->
<!--										</ul>-->
<!--									</li>-->
							</ul>
						</div>
					</nav>

					<!-- Site Overlay -->
					<div class="site-overlay"></div>

					<!-- About Section -->
					<about-section/>

					<!-- Resume Section -->
					<resume-section/>

					<!-- Skills Section -->
<!--						<skills-section/>-->

					<!-- Services Section -->
					<hobbies-section v-model="hobby" />

					<!-- Work Section -->
<!--					<work-section/>-->

					<!-- Projects Section -->
					<projects-section/>

					<!-- Contact Section -->
					<contact-section/>

					<!-- Social Section -->
					<social-section/>

					<!-- Footer Section -->
					<footer-section/>
<!--					</div>-->
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
// @ is an alias to /src
import Preloader from "@/components/Preloader";
import SideImage from "@/components/SideImage";
import AboutSection from "@/components/Sections/AboutSection";
import HobbiesSection from "@/components/Sections/HobbiesSection";
// import SkillsSection from "@/components/Sections/SkillsSection";
import ResumeSection from "@/components/Sections/ResumeSection";
import ContactSection from "@/components/Sections/ContactSection";
import WorkSection from "@/components/Sections/WorkSection";
import ProjectsSection from "@/components/Sections/ProjectsSection";
import SocialSection from "@/components/Sections/SocialSection";
import FooterSection from "@/components/Sections/FooterSection";

export default {
	name: "Home",
	components: {
		AboutSection,
		SideImage,
		HobbiesSection,
		// SkillsSection,
		ResumeSection,
		ContactSection,
		ProjectsSection,
		WorkSection,
		SocialSection,
		FooterSection,
		Preloader
	},
	data: function() {
		return {
			hobby: null,
		}
	}
};
</script>
