<template>
	<footer>
		<t-section>
			<b-col cols="12" class="text-center">
				<h4>Copyright &copy; 2022. All rights reserved.</h4>
			</b-col>
		</t-section>
	</footer>
</template>

<script>
import Section from "@/components/Section";

export default {
	name: "FooterSection",
	components: {
		"t-section": Section
	}
};
</script>

<style scoped>
	footer h4 {
		font-size: 8pt;
		letter-spacing: 1pt;
		margin: 80px auto;
		color: #1b1b1b;
	}
</style>