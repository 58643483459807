import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";


//plugins
import VueBootstrap from 'bootstrap-vue';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faMusic, faGamepad, faCamera, faMicrochip, faFutbol, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faStackOverflow, faStackExchange, faFacebook, faBandcamp, faSteam, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faMusic, faGamepad, faCamera, faMicrochip, faFutbol, faFileAlt, faCamera, faGithub, faStackOverflow, faStackExchange, faFacebook, faBandcamp, faSteam, faLinkedin);

//plugins css
import 'bootstrap-vue/dist/bootstrap-vue.css';

//theme
import './css/main.scss';

import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCXjXirovGfiZ8MUGqXb1Ffecq5VA5aHks",
  authDomain: "tutamkhamon-com.firebaseapp.com",
  databaseURL: "https://tutamkhamon-com-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "tutamkhamon-com",
  storageBucket: "tutamkhamon-com.appspot.com",
  messagingSenderId: "792482563949",
  appId: "1:792482563949:web:cdc18ce88801be4c3afc64"
};

const fireApp = initializeApp(firebaseConfig);
const fireData = getDatabase(fireApp);


Vue.use(VueBootstrap);
Vue.use({ install: Vue => {
  Vue.prototype.$fire = { app: fireApp, database: fireData };
}});

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
