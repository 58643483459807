<template>
	<div v-if="!finished" class="preloader-wrap" :class="{'hidden': hidden}">
		<div class="preloader">
			<div class="lines">
				<!--				<div class="line line-1"></div>-->
				<!--				<div class="line line-2"></div>-->
				<!--				<div class="line line-3"></div>-->
				<img src="_img/logo_snap_125.gif" alt="loading"/>
			</div>

		</div>
	</div>
</template>

<script>
	//TODO: change gif start point to be faster

	export default {
		name: "Preloader",
		props: {
			time: {
				type: Number,
				default: 500,
			}
		},
		data: function() {
			return {
				hidden: false,
				finished: false
			}
		},
		created() {
			window.addEventListener('load', () => {
				this.fade(this.time);
			});
		},
		mounted() {
			// window.addEventListener('load', () => {
			// 	this.fade(this.time);
			// });
		},
		methods: {
			fade(milliseconds) {
				setTimeout(this.hide, milliseconds);
				setTimeout(this.remove, milliseconds + 500);
			},
			hide() {
				this.hidden = true;
			},
			remove() {
				this.finished = true;
			}
		}
	}
</script>

<style scoped lang="scss">
	.preloader-wrap {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: #0C0C0C;
		z-index: 9999;
		overflow: hidden;
		transition: opacity .5s;

		&.hidden {
			opacity: 0;
		}
	}

	.preloader {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 60px;
		height: 40px;
		margin: -30px 0 0 -40px;

		img {
			width: 60px;
			height: 60px;
			mix-blend-mode: lighten;
		}

		.lines {
			width: 80px;
			height: 40px;
			position: absolute;

			.line {
				width: 90px;
				height: 15px;
				background-color: #fff;
				position: absolute;
				clip: rect(0,0,20px,0);

				&.line-1 {
					top: 0;
					animation: slide 2s ease 0.1s infinite;
				}
				&.line-2 {
					top: 15px;
					animation: slide 2s ease .25s infinite;
				}
				&.line-3 {
					top: 30px;
					animation: slide 2s ease .5s infinite;
				}
			}
		}

		.loading-text {
			position: absolute;
			top: 50px;
			text-align: center;
			width: 100%;
			color: #fff;
			font-size: 14px;
			font-family: "Montserrat", sans-serif;
			letter-spacing: 1px;
			line-height: 10px;
			height: 10px;
			text-transform: uppercase;
		}
	}

	@keyframes slide {
		0% {
			clip: rect(0,0,20px,0);
		}

		30% {
			clip: rect(0,80px,20px,0);
		}

		50% {
			clip: rect(0,80px,20px,0);
		}

		80% {
			clip: rect(0,80px,20px,80px);
		}

		100% {
			clip: rect(0,80px,20px,80px);
		}
	}

	@media only screen and (max-width : 375px) {
		.preloader {
			margin: -30px 0 0 -25px;
		}
	}

	/* Custom, iPhone Retina */
	@media only screen and (max-width : 320px) {
		.preloader {
			margin: -30px 0 0 -30px;
		}
	}

</style>
