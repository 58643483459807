<template>
	<t-section class="projects" id="projects" title="Cool Projects" line-spaced>

		<b-col v-for="project in projects" cols="12" md="4" :key="project.title">
			<a :class="project.type || 'popup-image'" :href="project.type ? project.url : project.image">
				<div class="portfolio-thumb">
					<img :src="project.image" class="img-fluid" alt="project">
					<div class="portfolio-overlay">
						<div class="portfolio-item">
							<h3>{{ project.title }}</h3>
							<p v-if="project.text"><small>{{ project.text }}</small></p>
						</div>
					</div>
				</div>
			</a>
		</b-col>

	</t-section>
</template>

<script>
import Section from "@/components/Section";

export default {
	name: "PortfolioSection",
	components: {
		't-section': Section
	},
	data: function() {
		return {
			'projects': [
				{
					image: "_img/magis.png",
					title: "Magis",
					type: "popup-youtube",
					text: "A shape shifting VR game",
					url: "https://drive.google.com/file/d/1r7QpQKqkcC5x4aNxP4-aJoAPiOMh-2By/preview"
				},{
					image: "_img/photo.jpg",
					title: "Photo Album",
					type: "external",
					text: "My digital photo album",
					url: "https://photo.tutamkhamon.com"
				},{
					image: "_img/blender.png",
					title: "Blender Asset Export Panel",
					type: "external",
					text: "A blender add-on",
					url: "https://github.com/TUTAMKHAMON/BlenderAssetExportPanel"
				},{
					image: "_img/cd_2.png",
					title: "CD",
					type: "external",
					text: "A simple web-based countdown",
					url: "https://cd.tutamkhamon.com"
				},{
					image: "_img/adobe_fonts_2.png",
					title: "Adobe Fonts Revealer",
					type: "external",
					text: "A tool for extracting Adobe fonts",
					url: "https://github.com/TUTAMKHAMON/adobe-fonts-revealer-windows-batch"
				}, {
					image: "_img/jp8000_2.webp",
					title: "JP-8000 SuperSaw",
					type: "external",
					text: "A Reaktor emulation",
					url: "https://www.native-instruments.com/en/reaktor-community/reaktor-user-library/entry/show/9323/"
				}
				// ,{
				// 	image: "_img/placeholder.png",
				// 	title: "vm",
				// 	type: "popup-vimeo",
				// 	url: "https://vimeo.com/158284739"
				// },{
				// 	image: "_img/placeholder.png",
				// 	title: "cert",
				// 	type: "popup-pdf",
				// 	url: "docs/201405163_MIEIC_748595_SD.pdf"
				// }
			]
		}
	},
};
</script>

<style lang="scss" scoped>
	.projects {
		padding-top: 150px;
	}

	.projects .line {
		margin-bottom: 60px;
	}

	.projects ul {
		padding: 0;
	}

	.projects ul li {
		cursor: pointer;
		margin: 10px 20px;
		color: #1b1b1b;
		font-size: 11pt;
		letter-spacing: 1pt;
		font-weight: 300;
		display: inline-block;
	}

	.projects img {
		width: 100%;
		height: 100%;
	}

	.projects img:hover {
		cursor: pointer;
		transform: rotate(2deg);
	}

	.projects .col-md-4 {
		padding-bottom: 30px;
	}

	.projects .portfolio-thumb {
		position: relative;
		width: 100%;
		transition: transform 400ms ease-in-out;
		.portfolio-overlay {
			position: absolute;
			background: #1b1b1b;
			color: #ffffff;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			text-align: center;
			vertical-align: top;
			opacity: 0;
			transition: all 0.4s ease-in-out;
			box-shadow: none;
		}

		&:hover {
			transform: scale(1.075) rotate(1deg);
			.portfolio-overlay {
				opacity: 0.7;
			}
		}
	}

	.projects .portfolio-item {
		position: absolute;
		top:50%;
		left: 50%;
		-webkit-transform:translate(-50%,-50%);
		-ms-transform:translate(-50%,-50%);
		transform:translate(-50%,-50%);
		width: 100%;
		padding: 1em;
	}

	.projects .portfolio-item h3 {
		font-size: 11pt;
	}
</style>