<template>
	<t-section class="contact" id="contact" title="contact">
		<b-col cols="12">
			<div class="form-bg">
				<form id="form" @submit.prevent="submit">
					<input v-model="contactForm.title" type="text" class="name" name="name" placeholder="NAME" required /><br />
					<input v-model="contactForm.email" type="email" class="email" name="email" placeholder="EMAIL" required /><br />
					<textarea v-model="contactForm.message" class="msg" name="msg" placeholder="MESSAGE" required></textarea>
					<button class="btn">SEND MESSAGE</button>
				</form>
			</div>
		</b-col>

		<b-col cols="12" sm="4">
			<h4 class="table">Phone:</h4>
			<h3><a href="tel:+351917405896"><small>+351</small> &nbsp;917 405 896</a></h3>
		</b-col>

		<b-col cols="12" sm="4">
			<h4 class="table">Email:</h4>
			<h3><a href="mailto:contact@joaoferreira.dev">contact@joaoferreira.dev</a></h3><!-- change to contact@joaoferreira.dev -->
		</b-col>
	</t-section>
</template>

<script>
import Section from "@/components/Section";

import { ref, push, set } from "firebase/database";

export default {
	name: "ContactSection",
	components: {
		't-section': Section
	},
	data() {
		return {
			contactForm: {
				title: '',
				email: '',
				message: ''
			}
		};
	},
	methods: {
		submit: function() {
			let c_id = push(ref(this.$fire.database, 'contacts'));
			set(c_id, {
				title: this.contactForm.title,
				email: this.contactForm.email,
				message: this.contactForm.message
			}).then(this.clear);
		},
		clear: function() {
			this.contactForm.email = "";
			this.contactForm.title = "";
			this.contactForm.message = "";
		}
	}
};
</script>

<style lang="scss" scoped>
	.contact {
		padding-top: 150px;
	}

	.contact h4 {
		color: #9e9e9e;
		font-size: 10pt;
		letter-spacing: 2pt;
	}

	.contact h3 {
		font-size: 12pt;
		letter-spacing: 1pt;
	}

	#form {
		padding: 40px 0 40px 0;
	}

	.form-bg .name {
		width: 100%;
		height: 40px;
		padding: 15px 15px;
		border: 0;
		font-size: 10pt;
		background-color: inherit;
		color: #1b1b1b;
		border-bottom: 2px solid #9e9e9e;
	}

	.form-bg .name:focus {
		transition: border .6s;
		-webkit-transition: border .6s;
		outline: none;
		border-bottom: 2px solid #1b1b1b;
	}

	.form-bg .email {
		margin-top: 30px;
		width: 100%;
		height: 40px;
		padding: 15px 15px;
		border: 0;
		font-size: 10pt;
		background-color: inherit;
		color: #1b1b1b;
		border-bottom: 2px solid #9e9e9e;
	}

	.form-bg .email:focus {
		transition: border .6s;
		-webkit-transition: border .6s;
		outline: none;
		border-bottom: 2px solid #1b1b1b;
	}

	.form-bg .msg {
		margin-top: 30px;
		width: 100%;
		height: 130px;
		padding: 15px 15px;
		border: 0;
		font-size: 10pt;
		background-color: inherit;
		color: #1b1b1b;
		border-bottom: 2px solid #9e9e9e;
	}

	.form-bg .msg:focus {
		transition: border .6s;
		-webkit-transition: border .6s;
		outline: none;
		border-bottom: 2px solid #1b1b1b;
	}

	.form-bg .btn {
		font-family: 'Roboto', sans-serif;
		font-weight: 500;
		font-size: 10pt;
		letter-spacing: 2pt;
		color: #fff;
		background-color: #1b1b1b;
		border-radius: 0px;
		padding: 15px 40px;
		margin-top: 50px;
		transition: all 200ms ease-out;

		&:hover {
			background-color: #191918;
			transform: translateY(-3px);
			box-shadow: #1b1b1b88 0 3px 3px;
		}

		&:active {
			transform: translateY(2px);
			box-shadow: none;
		}
	}

</style>