var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"left-wrapper section",attrs:{"lg":"4","tag":_vm.tag}},[_c('div',{staticClass:"image-wrapper"},[_c('div',{staticClass:"prism image-prism",class:{
			"prism-forward": _vm.face === 0,
			"prism-leftward": _vm.face === 1,
			"prism-backward": _vm.face === 2,
			"prism-rightward": _vm.face === 3,
		}},[_c('div',{staticClass:"prism-face prism-face-front",style:({'background-image': 'url(' + _vm.panes.F.img + ')' })}),_c('div',{staticClass:"prism-face prism-face-left",style:({'background-image': 'url(' + _vm.panes.LU.img + ')' })}),_c('div',{staticClass:"prism-face prism-face-back",style:({'background-image': 'url(' + _vm.panes.B.img + ')' })}),_c('div',{staticClass:"prism-face prism-face-right",style:({'background-image': 'url(' + _vm.panes.RD.img + ')' })})]),_c('div',{staticClass:"text-wrapper"},[_c('h2',[_c('div',{staticClass:"prism text-prism",class:{
				"prism-forward": _vm.face === 0,
				"prism-upward": _vm.face === 1,
				"prism-backward": _vm.face === 2,
				"prism-downward": _vm.face === 3,
			}},[_c('span',{staticClass:"prism-face prism-face-front"},[_vm._v(_vm._s(_vm.panes.F.title.main)+_vm._s(_vm.panes.F.title.sub ? ',' : '')),_c('br'),_vm._v(_vm._s(_vm.panes.F.title.sub))]),_c('span',{staticClass:"prism-face prism-face-up"},[_vm._v(_vm._s(_vm.panes.LU.title.main)+_vm._s(_vm.panes.LU.title.sub ? ',' : '')),_c('br'),_vm._v(_vm._s(_vm.panes.LU.title.sub))]),_c('span',{staticClass:"prism-face prism-face-back"},[_vm._v(_vm._s(_vm.panes.B.title.main)+_vm._s(_vm.panes.B.title.sub ? ',' : '')),_c('br'),_vm._v(_vm._s(_vm.panes.B.title.sub))]),_c('span',{staticClass:"prism-face prism-face-down"},[_vm._v(_vm._s(_vm.panes.RD.title.main)+_vm._s(_vm.panes.RD.title.sub ? ',' : '')),_c('br'),_vm._v(_vm._s(_vm.panes.RD.title.sub))])]),_c('small',[_vm._v("João Ferreira")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }