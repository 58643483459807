<template>
	<t-section class="work" id="work" title="Previous Work" line-spaced dark>
		<b-col cols="12">
			<b-row v-for="project of projects_count" :key="project" tag="article">
				<b-col cols="12">
					<h4 @click="openProject=project">{{projects[project-1].title}}</h4>
					<b-collapse accordion="test" :visible="project === openProject">
						<p> alaldkf kaka lks ks lkdj aslkk asdf asdfkjlkjl lbkas d alksdkfkfkalskdfjk akskkdflkja a asdlfklk kal aljçkjdkknknkioninioa oinao  aois i nvoiw oi owin a , ,,a'sdjf q a.  oweçkl a-a dsdmç ba´a wkn a.a </p>
						<p> alaldkf kaka lks ks lkdj aslkk asdf asdfkjlkjl lbkas d alksdkfkfkalskdfjk akskkdflkja a</p>
					</b-collapse>
<!--				<b-card :title="projects[project-1].title"></b-card>-->
				</b-col>
			</b-row>
			<router-link to="work" class="float-right">Older work</router-link>
		</b-col>
<!--		<b-col v-for="project in projects" cols="12" md="4" :key="project.title">-->
<!--			<a :class="project.type || 'popup-image'" :href="project.type ? project.url : project.image">-->
<!--				<div class="portfolio-thumb">-->
<!--					<img :src="project.image" class="img-fluid" alt="project">-->
<!--					<div class="portfolio-overlay">-->
<!--						<div class="portfolio-item">-->
<!--							<h3>{{ project.title }}</h3>-->
<!--							<p v-if="project.text"><small>{{ project.text }}</small></p>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</a>-->
<!--		</b-col>-->

	</t-section>
</template>

<script>
import Section from "@/components/Section";

export default {
	name: "PortfolioSection",
	components: {
		't-section': Section
	},
	computed: {
		projects_count: function() {
			return Math.min(this.projects.length, 3);
		}
	},
	data: function() {
		return {
			projects: [
				{
					title: "TVWall for the Paris Fashion week",
					date: "",
					short_description: "",
					description_cont: "",
					url: "",
					imgs: [
							"",
					]
				},
				{
					title: "Pedalboard + midi footswitch",
					date: "",
					short_description: "",
					description_cont: "",
					url: "",
					imgs: [
							"",
					]
				},
				{
					title: "Keyboard support",
					date: "",
					short_description: "",
					description_cont: "",
					url: "",
					imgs: [
							"",
					]
				},
			],
			openProject: -1
		}
	},
};
</script>

<style scoped>
	.work {
		padding-top: 80px;
		padding-bottom: 120px;
		background-color: #fafbfc;
	}

	.work .line {
		margin-bottom: 60px;
	}

	.work ul {
		padding: 0;
	}

	.work ul li {
		cursor: pointer;
		margin: 10px 20px;
		color: #1b1b1b;
		font-size: 11pt;
		letter-spacing: 1pt;
		font-weight: 300;
		display: inline-block;
	}

	.work img {
		width: 100%;
		height: 100%;
	}

	.work img:hover {
		cursor: pointer;
	}

	.work .col-md-4 {
		padding-bottom: 30px;
	}

	.work .portfolio-thumb {
		position: relative;
		width: 100%;
	}

	.work .portfolio-thumb .portfolio-overlay {
		position: absolute;
		background: #1b1b1b;
		color: #ffffff;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		vertical-align: top;
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}

	.work .portfolio-item {
		position: absolute;
		top:50%;
		left: 50%;
		-webkit-transform:translate(-50%,-50%);
		-ms-transform:translate(-50%,-50%);
		transform:translate(-50%,-50%);
		width: 100%;
		padding: 1em;
	}

	.work .portfolio-thumb:hover .portfolio-overlay {
		opacity: 0.7;
	}

	.work .portfolio-item h3 {
		font-size: 11pt;
	}
</style>