<template>
	<section class="bg">
		<b-container :class="{'container-dark': dark}">
			<b-row class="padding">
				<b-col cols="12" v-if="title">
					<h2 class="title">{{ title }}</h2>
					<hr class="line" :class="{'line-spaced': lineSpaced}">
				</b-col>
				<slot/>
			</b-row>
		</b-container>
	</section>
</template>

<script>
	export default {
		name: "Section",
		props: {
			title: String,
			dark: Boolean,
			lineSpaced: Boolean,
		}
	};
</script>

<style>

	.title {
		margin: 0;
		font-size: 11pt;
		font-weight: 700;
		letter-spacing: 3pt;
		color: #1b1b1b;
		text-transform: uppercase;
	}

	.line {
		margin: 20px 0;
		width: 25px;
		height: 1px;
		background-color: #9e9e9e;
	}

	.container-dark .line {
		background-color: #dcdcdc;
	}

	.line.line-spaced {
		margin-bottom: 60px;
	}

	@media only screen and (max-width : 480px) {
		.padding {
			padding: 0 10px;
		}
	}

	@media only screen and (max-width : 375px) {
		.padding {
			padding: 0 10px;
		}
	}

	@media only screen and (max-width : 320px) {
		.padding {
			padding: 0 10px;
		}
	}
</style>