<template>
	<b-col lg="4" class="left-wrapper section" :tag="tag">
		<div class="image-wrapper">
			<div class="prism image-prism" :class='{
				"prism-forward": face === 0,
				"prism-leftward": face === 1,
				"prism-backward": face === 2,
				"prism-rightward": face === 3,
			}'>

				<div class="prism-face prism-face-front" :style="{'background-image': 'url(' + panes.F.img + ')' }"/>
				<div class="prism-face prism-face-left"  :style="{'background-image': 'url(' + panes.LU.img + ')' }"/>
				<div class="prism-face prism-face-back"  :style="{'background-image': 'url(' + panes.B.img + ')' }"/>
				<div class="prism-face prism-face-right" :style="{'background-image': 'url(' + panes.RD.img + ')' }"/>
			</div>
			<div class="text-wrapper">
				<h2>
					<div class="prism text-prism" :class='{
					"prism-forward": face === 0,
					"prism-upward": face === 1,
					"prism-backward": face === 2,
					"prism-downward": face === 3,
				}'>
						<span class="prism-face prism-face-front">{{ panes.F.title.main }}{{panes.F.title.sub ? ',' : ''}}<br>{{ panes.F.title.sub }}</span>
						<span class="prism-face prism-face-up">{{ panes.LU.title.main }}{{panes.LU.title.sub ? ',' : ''}}<br>{{ panes.LU.title.sub }}</span>
						<span class="prism-face prism-face-back">{{ panes.B.title.main }}{{panes.B.title.sub ? ',' : ''}}<br>{{ panes.B.title.sub }}</span>
						<span class="prism-face prism-face-down">{{ panes.RD.title.main }}{{panes.RD.title.sub ? ',' : ''}}<br>{{ panes.RD.title.sub }}</span>
					</div>
					<small>João Ferreira</small>
				</h2>
			</div>
		</div>
	</b-col>
</template>

<script>

	import { defaults } from "lodash/object";

	export default {
		name: "SideImage",
		props: ['details', 'tag'],
		mounted() {
			document.getElementsByClassName('prism')[0].style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
			// document.documentElement.style.setProperty('--translationDistance', 'calc(100vw / 6 - var(--scroll-width))');
		},
		data: function () {
			return {
				face: 0,
				defaults: {
					img: "/_img/F000026s.webp",
					title: {
						main: "engineer",
						sub: "programmer"
					}
				},

				panes: {
					F: {
						img: "/_img/F000026s.webp",
						title: {
							main: "engineer",
							sub: "programmer",
						}
					},
					LU: {
						img: "/_img/F000026s.webp",
						title: {
							main: "engineer",
							sub: "programmer",
						}
					},
					B: {
						img: "/_img/F000026s.webp",
						title: {
							main: "engineer",
							sub: "programmer"
						}
					},
					RD: {
						img: "/_img/F000026s.webp",
						title: {
							main: "engineer",
							sub: "programmer"
						}
					}
				},

				lastOrderId: 0,
				lastTransitionId: 0,
				nextTransitionId: 0,

				currentlyVisibleImgs: {
					from: null,
					to: null
				},
				onTransitionFinished: []
			}
		},
		watch: {
			details: function(val) {
				if(val != null) {
					if(val) {
						this.startTransitionTimed( () => {
							let tmp = (this.face === 1 ? 2 : 1);
							if (this.getPane(this.face).img === val.img) return;
							this.goToPaneImg(tmp, val);
						}, val.img, 200);
					}
				} else {
					this.lastOrderId = 0;
					if(this.getPane(this.face).img !== this.defaults.img) {
						this.startTransitionTimed(() => {
							this.resetPaneToDefault();
						}, this.defaults.img, 500); //TODO: cancel pending transitions instantly
					}
				}
			}
		},
		methods: {
			getPane(i) {
				switch (i) {
					default:
					case 0:
						return this.panes.F;
					case 1:
						return this.panes.LU;
					case 2:
						return this.panes.B;
					case 3:
						return this.panes.RD;
				}
			},
			setPane(i, info) {
				switch (i) {
					default:
					case 0:
						this.panes.F = defaults(info, this.defaults);
						break;
					case 1:
						this.panes.LU = defaults(info, this.defaults);
						break;
					case 2:
						this.panes.B = defaults(info, this.defaults);
						break;
					case 3:
						this.panes.RD = defaults(info, this.defaults);
						break;
				}
			},
			goToPaneImg(paneId, info) {
				this.currentlyVisibleImgs.from = this.face;
				this.currentlyVisibleImgs.to = paneId;
				this.setPane(paneId, info);
				this.face = paneId;
			},
			resetPaneToDefault() {
				let tmp = Math.max(this.face - 1, 0);
				this.goToPaneImg(tmp);
			},
			transitionEnd() {
				this.currentlyVisibleImgs.from = null;
				this.currentlyVisibleImgs.to = null;
				this.onTransitionFinished.forEach( f => f());
				this.onTransitionFinished = [];
			},
			transitionTimedEnd() {
				let r2 = Math.random();
				this.lastTransitionId = r2;
				setTimeout(() => {
					if (this.lastTransitionId === r2) {
						this.transitionEnd();
					}
				}, 1000)
			},
			transitionWhenPossible (f, img) {
				if (!this.transitionHappening() || this.getPane(this.currentlyVisibleImgs.from).img === img) {
					f();
					this.transitionTimedEnd();
				} else {
					let r3 = Math.random();
					this.nextTransitionId = r3;
					this.onTransitionFinished.push(() => {
						if (r3 === this.nextTransitionId) {
							f();
							this.transitionTimedEnd();
						}
					});
				}
			},
			startTransitionTimed(f, img, delay=200) {
				let r = Math.random();
				this.lastOrderId = r;
				setTimeout( () => {
					if(this.lastOrderId === r) {
						this.transitionWhenPossible(f, img);
					}
				}, delay);
			},
			transitionHappening() {
				return this.currentlyVisibleImgs.from !== null || this.currentlyVisibleImgs.to !== null;
			}
		}
	};
</script>

<style scoped lang="scss">
	.left-wrapper {
		padding-right: 0;
		padding-left: 0;
		background-color: #0b0b0b;
	}

	.image-wrapper {
		--height-compensation: 97%;
		position: fixed;
		width: 33.3333%;
		height: var(--height-compensation);
		top: calc( (100% - var(--height-compensation)) / 2);
		//padding: 20px;
		background: url('/_img/F000026s.webp') center;
		background-size: cover;
		//mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1.0), transparent);
		--persp: 2000px;
		perspective: var(--persp);

		.text-wrapper {
			padding: 20px;
		}
		h2 {
			position: absolute;
			font-size: 32pt;
			font-weight: 700;
			//line-height: 28pt;
			line-height: 30pt;
			letter-spacing: 1pt;
			color: #fff;
			margin: 0;
			bottom: 20px;
			text-shadow: #00000088 0 0 20px;

			small {
				display: block;
				text-transform: uppercase;
				font-size: 12pt;
				margin-top: 20px;
				letter-spacing: 1pt;
				font-weight: inherit;
			}
		}
	}

	@media only screen and (max-width : 991px) {
		.image-wrapper {
			position: relative;
			width: 100%;
			height: 300px;
			top: 0;
			padding: 15px;
		}

		.image-wrapper h2 {
			font-size: 38px;
			line-height: 38px;
		}

		//.image-wrapper h2 span {
		//	font-size: 16px;
		//}

		.image-wrapper {
			padding: 20px;
		}
	}

	section.prismGrid {
		display: grid;
		grid-template-columns: repeat(3, 33%);
		grid-template-rows: repeat(3, 33%);
		justify-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		/* centering */
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.scene {
		--coteprism: 400px;
		/* r = 100 / tan(30) = 57.7 */
		position: relative;
		width: var(--coteprism);
		height: var(--coteprism);
		grid-row: 2/3;
		grid-column: 2/3;
	}

	.prism {
		position: relative;
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		//-webkit-animation: rotateTriangle 10s linear infinite;
		//animation: rotateTriangle 10s linear infinite;

		transition: transform 1s ease-in-out;

		&.image-prism {
			--scrollbar-width: 0;
			//--translationDistance: calc(100vw / 6);
			//--translationDistance: calc( (100vw - 17px)  / 6 );
			--translationDistance: calc( (100vw - var(--scrollbar-width)) /6 );
			width: 100%;
			height: 100%;

			&.prism-forward {
				transform: rotateY(0deg);
			}
			&.prism-leftward {
				transform: rotateY(90deg);
			}
			&.prism-backward {
				transform: rotateY(180deg);
			}
			&.prism-rightward {
				transform: rotateY(-90deg);
			}

			.prism-face {
				width: inherit;
				height: inherit;
				position: absolute;
				background: #f2f2f4 center;
				background-image: url('/_img/F000026s.webp');
				background-size: cover;

				&.prism-face-front {
					-webkit-transform: translate3d(0, 0, var(--translationDistance));
					transform: translate3d(0, 0, var(--translationDistance));
				}

				&.prism-face-left {
					-webkit-transform: rotateY(-90deg) translate3d(0, 0, var(--translationDistance));
					transform: rotateY(-90deg) translate3d(0, 0, var(--translationDistance));
				}

				&.prism-face-right {
					-webkit-transform: rotateY(90deg) translate3d(0, 0, var(--translationDistance));
					transform: rotateY(90deg) translate3d(0, 0, var(--translationDistance));
				}

				&.prism-face-back {
					-webkit-transform: rotateY(180deg) translate3d(0, 0, var(--translationDistance));
					transform: rotateY(180deg) translate3d(0, 0, var(--translationDistance));
				}
			}
		}


		&.text-prism {
			--translationDistance: .8em;
			--textOffset: .9em;
			bottom: 70px;
			transition: transform .75s ease-in-out 0.25s;

			.prism-face {
				position: absolute;
				transition: opacity .75s linear 0.25s;

				&.prism-face-front {
					transform: translate3d(0, 0, var(--translationDistance));
					opacity: 0;
				}

				&.prism-face-up {
					transform: rotateX(90deg) translate3d(0, 0, var(--translationDistance));
					opacity: 0;
				}

				&.prism-face-back {
					transform: rotateX(180deg) translate3d(0, 0, var(--translationDistance));
					opacity: 0;
				}

				&.prism-face-down {
					transform: rotateX(-90deg) translate3d(0, 0, var(--translationDistance));
					opacity: 0;
				}
			}

			&.prism-forward {
				transform: rotateX(0deg);
				.prism-face-front {
					opacity: 1;
				}
			}
			&.prism-upward {
				transform: translateY(var(--textOffset)) rotateX(-90deg);
				.prism-face-up {
					opacity: 1;
				}
			}
			&.prism-backward {
				transform: translateY(calc( 2 * var(--textOffset) )) rotateX(-180deg);
				.prism-face-back {
					opacity: 1;
				}
			}
			&.prism-downward {
				transform: translateY(calc( 3 * var(--textOffset) )) rotateX(90deg);
				.prism-face-down {
					opacity: 1;
				}
			}
		}
	}
	@media (max-width: 992px) {
		.image-prism {
			visibility: hidden;
		}
	}

</style>