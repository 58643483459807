<template>
	<t-section class="about" id="about">
		<b-col cols="12">
			<div class="text-wrapper">
				<h2 @mouseenter="nextGreeting" v-html="greeting"></h2>
<!--															<h5>OUR AWESOME STORY</h5>-->
				<p class="about-text">Welcome to my website. Here you can find personal, professional and hobby related information about me.</p>
			</div>
		</b-col>
		<b-col cols="12" md="3" class="table-text">
			<h4 class="table">Name:</h4>
			<h3>João Ferreira</h3>
		</b-col>
		<b-col cols="12" md="3" class="table-text">
			<h4 class="table">Location:</h4>
			<h3>Copenhagen, Denmark</h3>
		</b-col>
		<b-col cols="12" md="3" class="table-text">
			<h4 class="table">Degree:</h4>
			<h3>M.Engineer</h3>
		</b-col>
		<b-col cols="12" md="3" class="table-text">
			<h4 class="table">Employement:</h4>
			<h3>Freelancer</h3>
		</b-col>
	</t-section>
</template>

<script>
	import Section from "@/components/Section";
	import _ from 'lodash';

	const greetings = [
			"Hello",
			"Olá",
			"Hej",
			"Hola",
			"Salut",

	]

	export default {
		name: "AboutSection",
		components: {
			"t-section": Section
		},
		data() {
			return {
				currentGreetId: 0,
				currentGreeting: "Hello",
				currentChar: 100,
			};
		},
		methods: {
			nextGreeting: _.debounce(function(){
				let r = _.random(greetings.length-2);
				this.currentGreetId = r + (r >= this.currentGreetId ? 1 : 0);
				this.updateGreeting();
			}, 500, {
				leading: true,
				trailing: false,
			}),
			updateGreeting: _.throttle(function(idx = 0) {
				this.currentChar = idx;
				let nextGreeting = greetings[this.currentGreetId];
				if(idx >= nextGreeting.length) {
					if (idx < this.currentGreeting.length) {
						this.currentGreeting = this.currentGreeting.substring(0, idx) + this.currentGreeting.substring(idx + 1);
						_.delay(this.updateGreeting, 100, idx);
					}
				} else {
					this.currentGreeting = this.currentGreeting.substring(0,idx) + nextGreeting[idx] + this.currentGreeting.substring(idx+1);
					_.delay(this.updateGreeting, 100, idx+1);
				}
			}, 50)
		},
		computed: {
			greeting() {
				return this.currentGreeting.substring(0, this.currentChar) +
						"<span class='high'>" +
							this.currentGreeting.substring(this.currentChar, this.currentChar+1) +
						"</span>" +
						this.currentGreeting.substring(this.currentChar+1);
			}
		}
	};
</script>

<style scoped lang="scss">
	.about {
		padding-top: 150px;
		margin-top: 50px;

		img {
			border-radius: 180px;
		}

		h2 {
			font-size: 16pt;
			font-weight: 700;
			letter-spacing: 2pt;
			color: #000000;
			width: fit-content;
			clear: right;

			&::after {
				content: "!";
			}
		}

		h5 {
			font-size: 9pt;
			letter-spacing: 1pt;
		}

		p {
			font-size: 11pt;
			letter-spacing: 1pt;
			line-height: 19pt;
			margin: 0 auto;
			padding-top: 10px;
			color: #3B3B3B;
		}

		.table-text {
			margin-top: 30px;

		}

		.table {
			font-size: 10pt;
			letter-spacing: 1pt;
			color: #9e9e9e;
		}

		h3 {
			font-size: 12pt;
			letter-spacing: 1pt;
		}
	}
</style>

<style>
	h2 .high {
		display: inline-block;
		transform: translateY(-5px);
		transition: transform 200ms ease-out;
		/*text-decoration: underline;*/
	}
</style>