<template>
	<t-section class="footer-social" id="footer-social">
		<b-col cols="12" class="text-center">
			<div class="social-icons">
				<ul>
					<li v-for="social in socials" :key="social.name">
						<a :href="social.url" :class="social.class || ''">
<!--							<i class="fa" :class="'fa-'+social.name"/>-->
							<font-awesome-icon :icon="[social.type || 'fab', social.name]"/>
						</a>
					</li>
				</ul>
			</div>
		</b-col>
	</t-section>
</template>

<script>
import Section from "@/components/Section";

export default {
	name: "SocialSection",
	components: {
		't-section': Section
	},
	data: function() {
		return {
			socials: [
				{
					type: "fa",
					name: "file-alt",
					class: "popup-pdf",
					url: "_docs/CV.pdf",
				},
				{
					name: "linkedin",
					url: "https://www.linkedin.com/in/joao-ferreira-dev/",
				},
				{
					name: "github",
					url: "https://github.com/TUTAMKHAMON"
				}, {
					name: "stack-overflow",
					url: "https://stackoverflow.com/users/11841788/tutamkhamon"
				}, {
					name: "stack-exchange",
					url: "https://stackexchange.com/users/6861258/tutamkhamon?tab=accounts"
				},
				// {
				// 	name: "facebook",
				// 	url: "https://www.facebook.com/profile.php?id=100000743106138"
				// },
				{
					name: "bandcamp",
					url: "https://bandcamp.com/TUTAMKHAMON"
				}, {
					name: "steam",
					url: "https://steamcommunity.com/id/TUTAMKHAMON/"
				}
			]
		}
	}
};
</script>

<style scoped lang="scss">
	.footer-social {
		margin-top: 50px;
	}

	.social-icons {
		margin-bottom: 20px;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: inline;

				a {
					display: inline-block;
					margin-left: 8px;
					margin-right: 8px;
					margin-bottom: 15px;
					border-radius: 180px;
					border: 1px solid #1b1b1b;
					color: #1b1b1b;
					line-height: 50px;
					width: 50px;
					height: 50px;
					text-align: center;
					font-size: 18px;

					transition: all .25s ease-out;
					svg {
						transition: all .25s ease-out;
						filter: drop-shadow(0px 0px 0px #00000000);
					}
				}

				&:hover a {
					transform: rotateX(20deg) translateY(1px);
					box-shadow: #00000088 0 4px 8px -2px;
					svg {
						filter: drop-shadow(0px 4px 2px #00000088);
						transform: translateY(-5px);
					}
				}

				&:active a {
					transform: rotateX(20deg) translateY(4px);
					box-shadow: #00000088 0 2px 4px -1px;
					svg {
						filter: drop-shadow(0px 2px 1px #00000088);
						transform: translateY(-2.5px);
					}
				}
			}
		}
	}
</style>