<template>
	<t-section class="resume" id="resume" title="resume" dark>
		<div class="col-lg-12">
			<!-- Nav tabs -->
			<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
				<li class="nav-item borderFade" role="presentation">
					<a class="nav-link active" id="pills-home-tab" data-toggle="pill" data-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Education</a>
				</li>
				<li class="nav-item borderFade" role="presentation">
					<a class="nav-link" id="pills-profile-tab" data-toggle="pill" data-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Research</a>
				</li>
			</ul>



			<!-- Tab panes -->
			<div class="tab-content" id="pills-tabContent">
				<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
					<div class="container">
						<div class="row">
							<div class="col-md-3 col-xs-12">
								<p class="year">University</p>
							</div>
							<div class="col-md-9 col-xs-12">
								<h5>Informatics and Computing Engineering</h5>
								<p>Master's degree from <em>Faculty of Engineering of the University of Porto</em> (2014-2020).</p>
								<p>
									<a class="popup-pdf" href="_docs/201405163_MIEIC_748595_CERT.pdf">
										<i class="fa fa-caret-right mr-2"/>Master's Certificate
									</a>
								</p>
								<p>
									<a class="popup-pdf" href="_docs/201405163_MIEIC_748595_SD.pdf">
										<i class="fa fa-caret-right mr-2"/>Diploma Supplement
									</a>
								</p>
								<hr>
							</div>
							<div class="col-md-3 col-xs-12">
								<p class="year">Master Thesis</p>
							</div>
							<div class="col-md-9 col-xs-12">
								<h5>Interactive Multimodal and Procedurally-Assisted Creation of VR Environments</h5>
								<p>Exploration of virtual reality interaction capabilities to allow for accessible content creation the medium.</p>
								<p><a href="https://joaoferreira.dev/thesis"><i class="fa fa-caret-right mr-2"/>View content</a></p>
								<hr>
							</div>
							<div class="col-md-3 col-xs-12">
								<p class="year">English Certification</p>
							</div>
							<div class="col-md-9 col-xs-12">
								<h5>Cambridge ESOL Level 2 Certificate in ESOL International</h5>
								<p></p>
								<p>
									<a class="popup-pdf" href="_docs/ESOL_Cambridge_C1.pdf">
										<i class="fa fa-caret-right mr-2"/>View certificate
									</a>
								</p>
								<hr>
							</div>
						</div>
					</div>
				</div>



				<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
					<div class="container">
						<div class="row">
							<div class="col-md-3 col-xs-12">
								<p class="year">ISC'2019</p>
							</div>
							<div class="col-md-9 col-xs-12">
								<h5>Using Simulation Games for Traffic Model Calibration</h5>
								<p class="mb-1">Using virtual driving simulation to calibrate the Krauß car-following model by coupling the Unity 3D game engine with SUMO.</p>
								<p class="mt-1"><a href="https://www.eurosis.org/cms/index.php?q=taxonomy/term/58#ISC">
									<small>ISBN: 978-94-92859-0-75</small>
								</a></p>
								<p><a href="https://www.researchgate.net/publication/338864616_Using_Simulation_Games_for_Traffic_Model_Calibration">
									<i class="fa fa-caret-right mr-2"/>Full Text
								</a></p>
								<hr>
							</div>
							<div class="col-md-3 col-xs-12">
								<p class="year">Robot 2019</p>
							</div>
							<div class="col-md-9 col-xs-12">
								<h5>BulbRobot – Inexpensive Open Hardware and Software Robot Featuring Catadioptric Vision and Virtual Sonars</h5>
								<p class="mb-1">Exploring the use of a chrome plated light bulb as a mirror for the creation of inexpensive catadiopric vision capable robots.</p>
								<p class="mt-1"><a href="https://doi.org/10.1007/978-3-030-35990-4_45">
									<small>DOI: 10.1007/978-3-030-35990-4_45</small>
								</a></p>
								<p><a class="popup-pdf" href="https://joaoferreira.dev/research/docs/BulbRobot_-_Inexpensive_Open_Hardware_and_Software_Robo.pdf">
									<i class="fa fa-caret-right mr-2"/>Full text
								</a></p>
								<p><a class="popup-pdf" href="https://joaoferreira.dev/research/docs/BulbRobot_-_robot_2019_presentation.pdf">
									<i class="fa fa-caret-right mr-2"/>Presentation
								</a></p>
								<hr>
							</div>
							<div class="col-md-3 col-xs-12">
								<p class="year mt-1">IEEE Conference on Virtual Reality</p>
							</div>
							<div class="col-md-9 col-xs-12">
								<h5>Immersive Multimodal and Procedurally-Assisted Creation of VR Environments</h5>
								<p class="mb-1">Creation of a tool for expediting the creation of 3D scenes inside VR, leveraging 3D object repositories, voice recognition and procedural tools.</p>
								<p class="mt-1"><a href="https://doi.org/10.1109/VRW52623.2021.00012"><small>DOI: 10.1109/VRW52623.2021.00012</small></a></p>
								<hr>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</t-section>
</template>

<script>
import Section from "@/components/Section";

export default {
	name: "ResumeSection",
	components: {
		't-section': Section
	}
};
</script>

<style scoped lang="scss">
	.resume {
		margin-top: 120px;
		padding: 100px 0;
		background-color: #fafbfc;

		.nav-item {
			margin-bottom: 50px;
		}

		.nav-pills .nav-link {
			color: #969595;
			padding-left: 15px;

			&.active {
				background: none;
				color: #1b1b1b;
				font-family: 'Montserrat', sans-serif;
				letter-spacing: 1pt;
				cursor: pointer;
			}
		}

		.year {
			font-size: 12pt;
			font-weight: 400;
			letter-spacing: 2pt;
			color: #1b1b1b;
		}

		h5 {
			font-size: 14pt;
			letter-spacing: 0;
			line-height: 20pt;
			color: #1b1b1b;
			margin: 0;
		}

		p {
			font-size: 12pt;
			letter-spacing: 1pt;
			color: #969595;

			a {
				font-size: 12pt;
				letter-spacing: 1pt;
				color: #969595;
				font-family: inherit;

				&:hover {
					color: #1b1b1b;
					i {

					}
				}
			}

			i {
				font-size: 12pt;
			}

			small {
				font-weight: inherit;
			}
		}

		hr {
			margin: 50px 0;
			width: auto;
			height: 1px;
			background-color: #969595;
		}
	}

	.borderFade {
		padding-bottom: 10px;
		color: #1b1b1b;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			height: 2px;
			width: 100%;
			left: 0;
			bottom: 0;
			opacity: 0;
			transform: translateY(3px);
			background: #1b1b1b;
			transition: opacity 0.2s ease, transform 0.2s ease;
		}

		&:hover::after {
			opacity: 1;
			transform: translateY(0);
		}
	}
</style>